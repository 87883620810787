import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
// import Logs from './Logs';
// import WeTransfer from './App';

const root = ReactDOM.createRoot(document.getElementById('sample_we_transsfer_view_'));
root.render(  
  <React.StrictMode>
    <main>
      <b>exp</b>
    </main>
  </React.StrictMode>
);
reportWebVitals();
